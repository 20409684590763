<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="init">运营工具</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">短信验证</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="10rem"
              class
            >
              <el-form-item label="手机号：" prop="phone">
                <el-input v-model="ruleForm.phone" placeholder="请输入手机号"></el-input>
              
              </el-form-item>
              <el-form-item
                label="验证码："
                prop="smsCode"
              >
                <el-input v-model="ruleForm.smsCode" placeholder="请输入验证码" maxlength="6"   onkeyup="this.value=this.value.replace(/[^\d]/g,'') "></el-input>
              </el-form-item>
              <el-form-item
                label="增加过期时间："
                prop="smsbeOverdueTime"
              >
                <el-input v-model="ruleForm.smsbeOverdueTime" placeholder="请输入过期时间"   onkeyup="this.value=this.value.replace(/[^\d]/g,'') ">
                      <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            
              <div class="activeSetbtn">
                <el-button class="bgc-bv" @click="doSure" icon="el-icon-message">发送短信</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ActivityDetails",
  components: {},
  mixins: [List],
  data() {
       var smsCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入验证码"));
      } else if (
        value && value.length<6
      ) {
        callback(new Error("请输入六位验证码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
          phone : "",
smsCode : "666666",
smsbeOverdueTime : "10",
       
      },
      rules: {
        phone: [
          { required: true,   validator: this.$validatePhone, trigger: "blur" },
        ],
        smsCode: [
          { required: true,validator:smsCode, trigger: "blur" },
        ],
        smsbeOverdueTime: [
          { required: true, message: "请输入过期时间", trigger: "blur" },
        ],
      },
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {
  
    /* 确定 */
    doSure() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //接口
          let params = {
            mobile: this.ruleForm.phone,
            code: this.ruleForm.smsCode,
            time: this.ruleForm.smsbeOverdueTime,
          };
          this.$post(
            "biz/user/sendMsg",
              params
          )
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  message: "发送成功",
                  type: "success",
                });
              }
            })
            .catch((err) => {
              return;
            });
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.form-textarea {
  .el-textarea {
    .el-textarea__inner {
      min-height: 13rem !important;
      resize: none;
    }
  }
}
.activeSetbtn {
  display: flex;
  justify-content: center;
  padding-bottom: 0.75rem;
}
</style>
